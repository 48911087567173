import {  React, useState, useEffect,useRef} from "react";
import Header from "../layout/header";
// import { Container, Row, Col ,Button } from 'reactstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { TbMap2, TbCalendarTime, TbChevronRight } from "react-icons/tb";
import { BsChevronRight, BsLink45Deg, BsImages, BsFilePdf, BsPlayBtn, BsThreeDots , BsPencilSquare, BsDisplay} from "react-icons/bs";
import {Link , useNavigate,useParams } from "react-router-dom";
import FsLightbox from "fslightbox-react";
import Form from 'react-bootstrap/Form';
import globalVariables from "../../global-variables";
import { GetEventList } from "../../api/events-functions";
import { GetEventTypeDistinctYearList } from "../../api/event-type-distinct-year-functions";
import {GetEventsGalleryList} from "../../api/events-gallery-functions";
import axios from "axios";
import {Container ,Row ,Col, Button,Modal} from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha"
import CommonYearWiseFilter from "../../common/CommonYearWiseFilter";
import Metatag from "../../hooks/Metatag";  
import { colors } from "@mui/material";

export default function Events() {

    let { event_year } = useParams();
	const qs_event_year = event_year;

    //constant used for recaptcha
    const captchaRef = useRef(null);
    const sitekey =  globalVariables.variables.REACT_APP_SITE_KEY;
    // constant to be used on the page

    const [events, setEventsList] = useState(null);

    const [eventname, setEventsName] = useState(null);

    const [eventtypeyear, setEventTypeYearList] = useState([]);

    const[year_recent_Upcoming,setyear_recent_Upcoming] =useState(101);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const [show1, setShow1] = useState(false);
    const handleCloseSuccess = () => setShow1(false);


    const[evenet_id,setEvent_id] = useState(null);

    const navigate =  useNavigate();


    const handleShow = (e,p_event_id) => {
        setEvent_id(p_event_id);

        //get event name
        let _obj = new Object();
        _obj.event_id = p_event_id;
         axios.post(globalVariables.variables.strApiUrl + "/eventApi/geteventList",_obj)
         .then((response_list) => {
            
            if(response_list.data === "error")
            {
                 navigate("/error-page")
            }
            else
            {
                // pass the result in the success function of the respective page
                setEventsName(response_list.data[0][0].event_name);
            }
             
         });

        e.preventDefault();
        setShow(true);
    }


    // success function when the list is retrived
    const OnGetEventSuccess = (p_response) => {
        // set the list for looping
        setEventsList(p_response);
    }
    useEffect(()=>{
        setTimeout(() => {

        // make the object for getting the news list
        let _objEvent = new Object();
        
        _objEvent.event_status = 1;
            
            if(qs_event_year === "upcoming")
            {
                _objEvent.event_upcoming = "u";
            }
            //when event is recent 
            else if(qs_event_year === "recent")
            {
                //than not passing year
            }
            else
            {
                _objEvent.selected_year = qs_event_year;
            }

           //not getting seminar type event
            _objEvent.event_e_type = "e";
            
            
            // call the function to get the roadblock
            GetEventList(_objEvent,OnGetEventSuccess,navigate);
        }, 100);

    },[qs_event_year])

    useEffect(()=>{
        setTimeout(() => {
             // set year wise value for the dropdown 
             if (qs_event_year != undefined && qs_event_year != null && qs_event_year != "") {

                document.getElementById("ddlEventYear").value = qs_event_year;
            }
        }, 100);
   },[events])


    const [validated, setValidated] = useState(false);

    //function for validate html
    let _str_bool = true;
    function Check(event){

        var reg =/<(.|\n)*?>/g; 
        var ErrorText ='HTML tags are not allowed';
        
            if (reg.test(document.getElementById("text_name").value) == true) {
                event.preventDefault();
                document.getElementById("validate_text_name").innerHTML = ErrorText;
                _str_bool = false
            }
            else
            {
                document.getElementById("validate_text_name").innerHTML = "";
                _str_bool = true;
            }
           
        }

    const handleSubmit = (event) => {

        const form = event.currentTarget;

        const token = captchaRef.current.getValue();
        
        Check(event);
        
        if (form.checkValidity() === false ) {
            event.preventDefault();
            event.stopPropagation();
           
        }
        else if(!token){
            event.preventDefault();
            event.stopPropagation();
            document.getElementById("captcha").innerText = 'Please verify that you are not robot'
        }
        else{
            if(_str_bool === true)
            {
                document.getElementById("captcha").innerText = ''
                event.preventDefault();
                    let _obj = new Object();
                    _obj.event_regt_name = document.getElementById("text_name").value;
                    _obj.event_regt_email_id = document.getElementById("text_email").value;
                    _obj.event_regt_mobile_no = (document.getElementById("text_mobile").value);
                    _obj.event_regt_event_id = evenet_id;
    
    
                    //registration save
                    axios.post(globalVariables.variables.strApiUrl + "/eventRegisterApi/addEventRegister", _obj)
                    .then((response_list) => {

                        if(response_list.data === "error")
                        {
                             navigate("/error-page")
                        }
                        else
                        {
                            if(response_list.data[0].length > 0)
                            {
                                // document.getElementById("reg_frm").style.display = "none";
                                // document.getElementsByClassName('reg_frm_succ').style.display= null;
        
                                // pass the result in the success function of the respective page
                                setShow(false);
                                setShow1(true);
                            }
                        }
                        
                    });
            }
        }

        setValidated(true);
    };

    const [eventstypeyear, setEventsTypeYearList] = useState([]);
    const [events_gallery_toggler, setEventsGalleryToggler] = useState(false);
    const [events_gallery, setEventsGallery] = useState([]);
    const [events_gallery_titel, setEventsGalleryTitel] = useState([]);

    const [gallery_key, setGalleryKey] = useState("");

    const [videos, setvideosToggler] = useState(false);

    const [lightbox_video, setLightBoxVideo] = useState([]);

        // success function when events gallery is retrived
        const OnGetEventsGallerySuccess = (p_response) => {

            let _arrGallery = [];

            // loop and make the array for binding the gallery
            for (let i = 0; i < p_response.length; i++) {
                _arrGallery.push(globalVariables.variables.strApiUrl + "/events-gallery-image/" + p_response[i].egallery_pic);
            }

            // set the array in the constant
            setEventsGallery(_arrGallery);

            setGalleryKey(Date.now())

            // using time out for the value to get saved in state
            setTimeout(() => {
                // trigger the link click to display the news gallery popup
                document.getElementById("lnk_trigger_events_gallery_popup").click();
            }, 200);

        }


        // function to invoke photo gallery
        const InvokePhotoGallery = (e, p_event_id) => {
            e.preventDefault();

            // Make an object for getting news gallery
            let _objEventsGallery = new Object();
            _objEventsGallery.egallery_status = 1;
            _objEventsGallery.egallery_event_id = p_event_id;

            // call the function to get the news gallery
            GetEventsGalleryList(_objEventsGallery, OnGetEventsGallerySuccess,navigate);

        };

         // function to invoke video popup
         const InvokeVideoPopup = (e, p_event_video_url) => {

            e.preventDefault();

            let _arrVideos = [];
            _arrVideos.push(p_event_video_url);

            // set the path to the constant for displaying
            setLightBoxVideo(_arrVideos);

            // trigger the link click to display the popup
            document.getElementById("lnk_trigger_video_popup_event").click();

        };


     // things to be done when the page is loaded
     useEffect(() => {
        //make the object for getting the news type year list
        let _objEventTypeYear = new Object();
        _objEventTypeYear.event_status = 1;
        // _objEventTypeYear.event_etype_id = globalVariables.variables.strEvent_id;

        //Get event type yesr List
        axios.post(globalVariables.variables.strApiUrl + "/eventApi/getEventdistinctyear", _objEventTypeYear)
        .then((response_list) => {

            if(response_list.data === "error")
            {
                navigate("/error-page")
            }
            else
            {
                // pass the result in the success function of the respective page
                setEventTypeYearList(response_list.data[0]);
                
            }
            
        });

    }, []);

    return (
        <>
            <Header main_class="bg-header-home"></Header>
            <Metatag title="Events at CMinds IIT Bombay | AI-ML Events"
                     keywords="Events at CMinds IIT Bombay, AI-ML Events, Artificial Intelligence Events, Machine Learning Events, Data Science Events"
                     description="Artificial Intelligence, Machine Learning, Data Science Events at CMinds IITB."/>
            
            <div className="container-fluid visual-container-1920 px-0">
                <div className="bg-visual-inside position-relative" style={{ backgroundImage: "url('../images/masthead-events.jpg')" }}>
                    <img src={globalVariables.variables.strDomainUrl + "images/masthead-spacer.png"} className="img-fluid" alt="" title="" />
                    <Container className="visual-text mx-auto d-flex align-items-center">
                        <h1 className="fw-semibold wow fadeInUp">Events</h1>
                        <div className="ms-4 wow fadeInUp">
                            <CommonYearWiseFilter yearList={eventtypeyear} _id={"ddlEventYear"} navigate_url={"events"}/>
                        </div>
                    </Container>
                </div>
            </div>
            {
                events != null ?

                    events.length > 0 ?

                    <div className="container-fluid px-0 my-md-5 my-4">
                        <Container className="py-xl-5">
                            <Row className="row-cols-1 row-cols-lg-2 g-4 g-lg-5">
                            {
                                events.map((val)=>{
                                    return(
                                            <Col>
                                            <div className="events h-100">
                                                <div className="d-md-flex border border-primary d-block h-100">


                                                    <div className="py-md-3 pb-3">
                                                        <div className="dates lh-sm d-md-block d-flex">

                                                            {/*when from and to date are diffrent  */}

                                                            {
                                                                val.event_from != val.event_to ?
                                                                        <>

                                                                            {
                                                                                val.from_mon != val.to_mon ?
                                                                                <>
                                                                                        <h2 className="fw-bolder m-0 text-white lh-1 me-md-0 me-1 wow fadeInUp">
                                                                                        {val.from_day}
                                                                                        </h2>
                                                                                            <h6 className="fw-normal m-0 wow fadeInUp">
                                                                                            {val.from_mon} {val.from_year}

                                                                                        </h6>
                                                                                        <p className="my-2">To</p>
                                                                                        <h2 className="fw-bolder m-0 text-white lh-1 me-md-0 me-1 wow fadeInUp">
                                                                                            {val.to_day}
                                                                                        </h2>
                                                                                        <h6 className="fw-normal m-0 wow fadeInUp">
                                                                                            {val.to_mon} {val.to_year}
                                                                                        </h6>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <h2 className="fw-bolder m-0 text-white lh-1 me-md-0 me-1 wow fadeInUp">
                                                                                        {val.from_day}-{val.to_day}
                                                                                    </h2>
                                                                                        <h6 className="fw-normal m-0 wow fadeInUp">
                                                                                        {val.from_mon}<br className="d-md-block d-none" /> {val.from_year}

                                                                                    </h6>
                                                                                </>

                                                                            }

                                                                    </>

                                                                :
                                                                // when from and to date same
                                                                <>
                                                                    {
                                                                            //when from and to date's month is same
                                                                        val.from_mon != val.to_mon ?
                                                                        <>
                                                                            <h2 className="fw-bolder m-0 text-white lh-1 me-md-0 me-1 wow fadeInUp">
                                                                            {val.from_day}
                                                                            </h2>
                                                                                <h6 className="fw-normal m-0 wow fadeInUp">
                                                                                {val.from_mon} {val.from_year}

                                                                            </h6>
                                                                            <p className="my-2">To</p>
                                                                            <h2 className="fw-bolder m-0 text-white lh-1 me-md-0 me-1 wow fadeInUp">
                                                                                {val.to_day}
                                                                            </h2>
                                                                            <h6 className="fw-normal m-0 wow fadeInUp">
                                                                                {val.to_mon} {val.to_year}
                                                                            </h6>
                                                                            </>
                                                                        :
                                                                        <>
                                                                            <h2 className="fw-bolder m-0 text-white lh-1 me-md-0 me-1 wow fadeInUp">
                                                                                {val.from_day}
                                                                            </h2>
                                                                                <h6 className="fw-normal m-0 wow fadeInUp">
                                                                                {val.from_mon} <br className="d-md-block d-none" />{val.from_year}
                                                                            </h6>
                                                                        </>
                                                                    }
                                                                </>


                                                            }
                                                        </div>
                                                    </div>


                                                    <div className="flex-grow-1 px-xl-5 px-4 py-3 d-flex flex-column">
                                                        <h5 className="fw-bold lh-sm mb-3 wow fadeInUp"> {val.event_name}</h5>
                                                        <p className=" mb-3 wow fadeInUp"> {val.event_caption}</p>
                                                        <div className="mb-4 d-flex flex-wrap gap-3">

                                                            {/* venue */}
                                                            {
                                                                 val.event_venue != null && val.event_venue != "" ?
                                                                        <div className="wow fadeInUp"><TbMap2 className="h5 m-0 text-primary" /> {val.event_venue}</div>
                                                                : null
                                                            }
                                                            {/* time */}
                                                            {
                                                                val.event_time != null && val.event_time != "" ?
                                                                <div className="wow fadeInUp"><TbCalendarTime className="h5 m-0 text-primary" /> {val.event_time}</div>
                                                                :
                                                                null
                                                            }

                                                        </div>
                                                        <div className="mt-auto">
                                                            <div className="d-flex gap-2 gap-xl-3">

                                                                {/* link */}
                                                                {
                                                                    val.event_url != null && val.event_url != "null" && val.event_url != "" ?
                                                                    <a href={val.event_url} target="_blank" className="btn btn-sm btn-outline-primary rounded-0 wow fadeInUp"><BsLink45Deg className="h6 m-0" /></a>
                                                                    :
                                                                    null
                                                                }

                                                                 {/* Photo Gallery */}
                                                                 {
                                                                    parseInt(val.event_gallery_count) > 0 ?
                                                                    <a className="btn btn-sm btn-outline-primary rounded-0 wow fadeInUp" onClick={event => InvokePhotoGallery(event, val.event_id)}><BsImages className="h6 m-0" /></a>
                                                                    :
                                                                    null
                                                                 }

                                                                 {/* Document */}
                                                                 {
                                                                    val.event_document != null && val.event_document != "null" && val.event_document != "" ?
                                                                        <a href={globalVariables.variables.strApiUrl + "/events-documents/" + val.event_document} target="_blank" className="btn btn-sm btn-outline-primary rounded-0 wow fadeInUp"><BsFilePdf className="h6 m-0" /></a>
                                                                        :
                                                                        null
                                                                }

                                                                {/* Video */}
                                                                {
                                                                    {/* Detail */} != null && val.event_video_link != "null" && val.event_video_link != "" ?
                                                                        <a className="btn btn-sm btn-outline-primary rounded-0 wow fadeInUp" onClick={event => InvokeVideoPopup(event, val.event_video_link)}><BsPlayBtn className="h6 m-0" /></a>
                                                                        :
                                                                        null
                                                                }
                                                                {/* Detail */}
                                                                <Link to={"/events-details/" + val.event_pagename}  className="btn btn-sm btn-outline-primary rounded-0 wow fadeInUp"><BsThreeDots className="h6 m-0" /></Link>

                                                                {/* Registration */}
                                                                {
                                                                   val.event_register === 1 ?
                                                                   <Link onClick={(e)=>handleShow(e,val.event_id)} className="btn btn-sm btn-outline-primary rounded-0 ms-auto wow fadeInUp"><BsPencilSquare className="h6 m-0" /> Register</Link>
                                                                   : null
                                                                }


                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                            </Row>
                        </Container>
                    </div>
                    :
                    <div className="container-fluid px-0 my-md-5 my-4">
                        <Container >
                            <Row>
                                <span className="text-center">No Records Found</span>
                            </Row>
                        </Container>
                     </div>
                :
                <div className="container-fluid px-0 my-md-5 my-4">
                    <Container >
                        <Row>
                            <span className="text-center">Loading...</span>
                        </Row>
                    </Container>
                </div>

            }

            {/* events Gallery popup */}
            {/* this link will open the news gallery popup which will be hidden because the button from the grid is causing issue */}
            <a onClick={(e) => {
                e.preventDefault();
                setEventsGalleryToggler(!events_gallery_toggler);
            }}
                className="d-none"
                id="lnk_trigger_events_gallery_popup"></a>

            <FsLightbox
                toggler={events_gallery_toggler}
                sources={events_gallery}
                key={gallery_key}
            />

             {/* Video popup */}
            {/* this link will open the video popup which will be hidden because the button from the grid is causing issue */}
            <a onClick={(e) => {
                e.preventDefault();
                setvideosToggler(!videos);
            }}
                className="d-none"
                id="lnk_trigger_video_popup_event"></a>

            <FsLightbox
                toggler={videos}
                sources={lightbox_video}
            />

            <Modal show={show} scrollable centered onHide={handleClose}>
                <Modal.Header closeButton className="align-content-center bg-primary border-0 rounded-0 academics-popup">
                    <Container>
                        <Modal.Title className="text-white fw-bold m-0 h5">
                            Registration Form
                        </Modal.Title>
                    </Container>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                    
                        <p className="fw-semibold">{eventname}</p>
                        <hr className="mt-0" />
                        <p className="small">Please fill in the form below to Register.<br />
                            Fields marked (*) are mandatory.
                        </p>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row className="mb-3 g-3">
                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                    {/* <Form.Label>Name *</Form.Label> */}
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Name *"
                                        defaultValue=""
                                        id="text_name"
                                    />
                                    <lable id="validate_text_name" style={{color:"red",fontSize:"14px"}}></lable>
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="validationCustom02">
                                    {/* <Form.Label>Email *</Form.Label> */}
                                    <Form.Control
                                        required
                                        type="email"
                                        placeholder="Email *"
                                        defaultValue=""
                                        id="text_email"
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="validationCustom02">
                                    {/* <Form.Label>Mobile No. *</Form.Label> */}
                                    <Form.Control
                                        required
                                        type="number"
                                        placeholder="Mobile No. *"
                                        defaultValue=""
                                        id="text_mobile"
                                    />
                                </Form.Group>
                                 <Form.Group as={Col} md="12" controlId="validationCustom02">
                                    {/* <Form.Label>Captcha</Form.Label> */}
                                    {sitekey &&
                                    <ReCAPTCHA
                                        sitekey={sitekey}
                                        ref={captchaRef}
                                        size="normal"
                                    />
                                    }
                                    <span className="text-danger fs-6" id="captcha"></span>
                                </Form.Group>
                            </Row>

                            <Button type="submit">Submit</Button>
                        </Form>

                    </Container>
                </Modal.Body>
            </Modal>
            

            <Modal show={show1} scrollable centered onHide={handleCloseSuccess}>
                <Modal.Header closeButton className="align-content-center bg-primary border-0 rounded-0 academics-popup">
                    <Container>
                        <Modal.Title className="text-white fw-bold m-0 h5">
                            Thank You
                        </Modal.Title>
                    </Container>
                </Modal.Header>
                <Modal.Body>
                        <Container>
                                    <div className="py-5 text-center">
                                        <p>Your Registration is Successfull,<br/> we will get back to you</p>
                                    </div>
                        </Container>
                </Modal.Body>
            </Modal>



            <Breadcrumb className="breadcrumb-panel">
            <Breadcrumb.Item href={globalVariables.variables.strDomainUrl}>Home</Breadcrumb.Item>
                <Breadcrumb.Item active>News & Events</Breadcrumb.Item>
                <Breadcrumb.Item active>Events</Breadcrumb.Item>
            </Breadcrumb>
        </>
    );
};