

module.exports = global.config = {
  variables: {

    //My local 
    // strDomainUrl: "/" ,
    // strApiUrl: "http://192.168.2.203:3002",

    //Designscape local (192)
    // strDomainUrl: "http://192.168.2.2/c/c-minds-react/website/" ,
    // strApiUrl: "http://192.168.2.2:3010",

    // //IIT live
    strDomainUrl: "https://www.minds.iitb.ac.in/",
    strApiUrl: "https://www.minds.iitb.ac.in/site",

    //IIT staging
    //  strDomainUrl: "http://10.129.134.117/",
    //  strApiUrl: "http://10.129.134.117:3002",

    //Founders and Donors category id
    strPeopleCategoty_id: "42b26726-5780-11ee-ba48-f8b156be9900",

    //Founders and Donors category page name 
    strPeopleCategoty_pagename: "founders-and-donors",

    //road block id
    strRoadBlock_id: "73a98b08-d383-11ed-918d-5254fbc0d86c",

    // news type id 
    strNewsTypeForAdmission_id: "30314523-d927-11ed-918d-5254fbc0d86c",
    strNewsTypeForNews_id: "0d2d7729-d927-11ed-918d-5254fbc0d86c",
    REACT_APP_SITE_KEY: "6LfCJzgpAAAAAJbKL_6YbgtzRQPXY1Zmx3SPd_jD",

    // REACT_APP_SECRET_KEY : "6Ld0ay0nAAAAAFs4RDIqHHnZcQKJkt7bSLoLR61E"
    strEventSeminar_id: "14f5b842-25f2-11ee-a1c8-10e7c607eeca",

    strEventTypeThesis_id: "6770bf45-0ec9-11f0-87cb-92077df9182f",

    strEvent_id: "cd82c526-1fe6-11ee-8507-10e7c607eeca",

    strSetting_id: "ebbf9735-dce5-11ed-9246-10e7c607eeca"
  }
  // other global config variables you wish
};