import  { React,useState , useEffect } from "react";
import Header from "../layout/header";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Button } from 'reactstrap';
import global from "../../global-variables";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { TbMap2, TbCalendarTime, TbCalendar, TbChevronRight } from "react-icons/tb";
import { BsChevronRight, BsLink45Deg, BsImages, BsFilePdf, BsPlayBtn, BsChevronLeft } from "react-icons/bs";
import { Link } from "react-router-dom";
import FsLightbox from "fslightbox-react";
import Form from 'react-bootstrap/Form';
import { GetEventList } from "../../api/events-functions";
import { GetEventsGalleryList } from "../../api/events-gallery-functions";

import Metatag from "../../hooks/Metatag";

export default function EventsDetails() {

    const navigate = useNavigate();

    //Getting id from url
    let { page_name } = useParams();
    const qs_event_pagename = page_name;

    // constant to be used on the page
    const [events, setEventsList] = useState(null);

    const [eventstypeyear, setEventsTypeYearList] = useState([]);
    const [events_gallery_toggler, setEventsGalleryToggler] = useState(false);
    const [events_gallery, setEventsGallery] = useState([]);
    const [events_gallery_titel, setEventsGalleryTitel] = useState([]);

    const [gallery_key, setGalleryKey] = useState("");

    const [videos, setvideosToggler] = useState(false);

    const [lightbox_video, setLightBoxVideo] = useState([]);


    const [toggler, setToggler] = useState(false);


        // success function when the list is retrived
        const OnGetEventsSuccess = (p_response) => {
        // set the list for looping
        setEventsList(p_response);
        }

        // success function when events gallery is retrived
        const OnGetEventsGallerySuccess = (p_response) => {

        let _arrGallery = [];

        // loop and make the array for binding the gallery
        for (let i = 0; i < p_response.length; i++) {
        _arrGallery.push(global.variables.strApiUrl + "/events-gallery-image/" + p_response[i].egallery_pic);
        }

        // set the array in the constant
        setEventsGallery(_arrGallery);

        setGalleryKey(Date.now())

        // using time out for the value to get saved in state 
        setTimeout(() => {
        // trigger the link click to display the news gallery popup
        document.getElementById("lnk_trigger_events_gallery_popup").click();
        }, 200);

        }


        // function to invoke photo gallery
        const InvokePhotoGallery = (e, p_event_id) => {
        e.preventDefault();

        // Make an object for getting news gallery
        let _objEventsGallery = new Object();
        _objEventsGallery.egallery_status = 1;
        _objEventsGallery.egallery_event_id = p_event_id;

        // call the function to get the news gallery
        GetEventsGalleryList(_objEventsGallery, OnGetEventsGallerySuccess,navigate);

        };

        // function to invoke video popup
        const InvokeVideoPopup = (e, p_event_video_url) => {

        e.preventDefault();

        let _arrVideos = [];
        _arrVideos.push(p_event_video_url);

        // set the path to the constant for displaying
        setLightBoxVideo(_arrVideos);

        // trigger the link click to display the popup
        document.getElementById("lnk_trigger_video_popup_event").click();

        };

        // function to navigate to the news page
        const RedirectToEventsPage = () => {
            useNavigate("/events");
        };


        // things to be done when the page is loaded
        useEffect(() => {

        //make the object for getting the news type year list
        let _objEvent = new Object();
        _objEvent.event_pagename = qs_event_pagename;
        // call the function to get the roadblock
        GetEventList(_objEvent,OnGetEventsSuccess,navigate);

        }, []);


    return (
        <>
            <Header main_class="bg-header-home"></Header>
            <div className="container-fluid visual-container-1920 px-0">
                <div className="bg-visual-inside position-relative" style={{ backgroundImage: "url('../images/masthead-events.jpg')" }}>
                    <img src={global.variables.strDomainUrl + "images/masthead-spacer.png"} className="img-fluid" alt="" title="" />
                    <Container className="visual-text mx-auto d-flex align-items-center">
                        <h1 className="fw-semibold wow fadeInUp">Events</h1>
                    </Container>
                </div>
            </div>


            {
                events != null ?

                    events.length > 0 ?
                    <div className="container-fluid px-0 my-lg-5">
                        {
                            events[0] != undefined && events[0] != null ?
                            <Metatag title={events[0]?.event_pagetitle} keywords={events[0]?.event_meta_keywords} description={events[0]?.event_meta_description}/>
                            :
                            null
                        }
                        <Container className="py-lg-5">
                            <div className="border-1 border-primary">
                                <Row className="px-lg-3">
                                    <Col className="col-12">
                                        <div className="h-100 py-4 py-md-5">
                                            <div className="px-lg-5 px-md-4 position-relative">
                                                <div className="mb-3">
                                                    <h5 className="fw-semibold lh-sm wow fadeInUp">
                                                        {events[0].event_name}
                                                        {/* Provable and Efficient Algorithms for Heterogeneous and Byzantine Robust Federated Learning */}
                                                </h5>
                                                </div>
                                                <div className="mb-4 d-flex flex-wrap gap-3">
                                                <div className="wow fadeInUp"><TbCalendar className="h5 m-0 text-primary" /> {events[0].event_from_date}</div>
                                                    {
                                                        //event vanue
                                                        events[0].event_venue != null && events[0].event_venue != "" ? <div className="wow fadeInUp"><TbMap2 className="h5 m-0 text-primary" /> {events[0].event_venue}</div>
                                                        : null
                                                    }
                                                    {
                                                        //event time
                                                        events[0].event_time != null && events[0].event_time != "" ? <div className="wow fadeInUp"><TbCalendarTime className="h5 m-0 text-primary" /> {events[0].event_time}</div>
                                                        :null
                                                    }
                                                    
                                                    
                                                </div>
                                                <div className="mb-3 wow fadeInUp"><i className="mb-2 d-block"></i></div>
                                                <div className="mb-3 d-flex gap-3 wow fadeInUp">
                                                                {/* link */}
                                                                {
                                                                    events[0].event_url != null && events[0].event_url != "null" && events[0].event_url != "" ?
                                                                    <a href={events[0].event_url} target="_blank" className="btn btn-sm btn-outline-primary rounded-0 wow fadeInUp"><BsLink45Deg className="h6 m-0" /></a>
                                                                    :
                                                                    null
                                                                }
                                                                
                                                                 {/* Photo Gallery */}
                                                                 {
                                                                    parseInt(events[0].event_gallery_count) > 0 ?
                                                                    <a className="btn btn-sm btn-outline-primary rounded-0 wow fadeInUp" onClick={event => InvokePhotoGallery(event, events[0].event_id)}><BsImages className="h6 m-0" /></a>
                                                                    :
                                                                    null
                                                                 }
                                                                
                                                                 {/* Document */}
                                                                 {
                                                                    events[0].event_document != null &&  events[0].event_document != "null" && events[0].event_document != "" ?
                                                                        <a href={global.variables.strApiUrl + "/events-documents/" + events[0].event_document} target="_blank" className="btn btn-sm btn-outline-primary rounded-0 wow fadeInUp"><BsFilePdf className="h6 m-0" /></a>
                                                                        :
                                                                        null
                                                                }

                                                                {/* Video */}
                                                                {
                                                                    events[0].event_video_link != null && events[0].event_video_link != "null" && events[0].event_video_link != "" ?
                                                                        <a className="btn btn-sm btn-outline-primary rounded-0 wow fadeInUp" onClick={event => InvokeVideoPopup(event, events[0].event_video_link)}><BsPlayBtn className="h6 m-0" /></a>
                                                                        :
                                                                        null
                                                                }
        
                                                    

                                                </div>
                                                <p className=" mb-3 wow fadeInUp"> {events[0].event_caption}</p>
                                                 {/* Details */}
                                                 {
                                                        events[0].event_details != null &&  events[0].event_details != "null" &&  events[0].event_details != "" ?
                                                            <div className="mb-3 wow fadeInUp ck-content"><span dangerouslySetInnerHTML={{ __html:  events[0].event_details }}></span></div>
                                                            : null
                                                    }
                                                

                                                <div className="mt-5 wow fadeInUp">
                                                        <Button onClick={() => navigate(-1)} className="btn btn-primary px-md-4 py-md-3 px-3 py-2 fw-light text-uppercase rounded-0 fw-normal">
                                                            Back
                                                        </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                :
                    <div className="container-fluid px-0 my-md-5 my-4">
                    <Container className="py-xl-5">
                        <div className="border-1">
                            <Row className="px-lg-3">

                                <Col className="col-12 col-lg-6 col-xl-8  order-lg-1">
                                    <div className="h-100 py-4 py-md-5">
                                        <div className="px-md-5 position-relative">
                                            <div className="mb-3">
                                                <h5 className="fw-semibold lh-sm wow fadeInUp">
                                                    The resource you are looking for does not exists or has been removed by the administrator.
                                                </h5>
                                            </div>

                                            <div className="mt-5 wow fadeInUp">
                                                <Button onClick={() => navigate(-1)} className="btn btn-primary px-md-4 py-md-3 px-3 py-2 fw-light text-uppercase rounded-0 fw-normal">
                                                    Back
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                            </Row>
                        </div>
                    </Container>

                    </div>

            :
            <div className="container-fluid px-0 my-md-5 my-4">
                <Container className="py-xl-5">
                    <div className="border-1">
                        <Row className="px-lg-3">

                            <Col className="col-12 col-lg-6 col-xl-8  order-lg-1">
                                <div className="h-100 py-4 py-md-5">
                                    <div className="px-md-5 position-relative">
                                        <div className="mb-3">
                                            <h5 className="fw-semibold lh-sm wow fadeInUp">
                                                Loading...
                                            </h5>
                                        </div>

                                        <div className="mt-5 wow fadeInUp">
                                            <Button onClick={() => navigate(-1)} className="btn btn-primary px-md-4 py-md-3 px-3 py-2 fw-light text-uppercase rounded-0 fw-normal">
                                                Back
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </div>
                </Container>

            </div>

        }

        {/* events Gallery popup */}
            {/* this link will open the news gallery popup which will be hidden because the button from the grid is causing issue */}
            <a onClick={(e) => {
                e.preventDefault();
                setEventsGalleryToggler(!events_gallery_toggler);
            }}
                className="d-none"
                id="lnk_trigger_events_gallery_popup"></a>

            <FsLightbox
                toggler={events_gallery_toggler}
                sources={events_gallery}
                key={gallery_key}
            />

             {/* Video popup */}
            {/* this link will open the video popup which will be hidden because the button from the grid is causing issue */}
            <a onClick={(e) => {
                e.preventDefault();
                setvideosToggler(!videos);
            }}
                className="d-none"
                id="lnk_trigger_video_popup_event"></a>

            <FsLightbox
                toggler={videos}
                sources={lightbox_video}
            />

         
            <Breadcrumb className="breadcrumb-panel">
            <Breadcrumb.Item href={global.variables.strDomainUrl}>Home</Breadcrumb.Item>
                <Breadcrumb.Item active>News & Events</Breadcrumb.Item>
                {
                    events != null?
                    <Breadcrumb.Item active>{events[0]?.event_name}</Breadcrumb.Item>
                    :
                    null
                }
            </Breadcrumb>
        </>
    );
};