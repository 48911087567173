import React, { useState, useEffect, useRef } from 'react';
import global from "../../global-variables";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { HashLink } from "react-router-hash-link";

import { FaFacebookF, FaTwitter, FaYoutube, FaWhatsapp, FaLinkedinIn, FaInstagram, FaPinterest, FaBlogger } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import axios from 'axios';
import $ from "jquery";
import TrackingCode from '../../hooks/TrackingCode.js';
import WOW from 'wowjs';

import { Container } from 'reactstrap';
import globalVariables from '../../global-variables';

export const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth' // Optional smooth scrolling
    });
};

export default function Header(props) {


    const ref = useRef(null);
    const navigate = useNavigate();
    //Getting id from url
    let { rcategory_id } = useParams();
    const qs_rcategory_id = rcategory_id;

    const [researchcat, setResearchCategoryList] = useState([]);

    let conversion_code = "";

    if (props.conversion_code != undefined) {
        conversion_code = 1;
    }
    else {
        conversion_code = 2;
    }



    //serach  function
    function url_generator(url) {
        var url_generated = "";
        // For local host
        if (window.location.href.indexOf('localhost') != -1) {
            url_generated = url;
        }

        //    // For my machine Server
        else if (window.location.href.indexOf('192.168.2.202') != -1) {
            // url_generated = "/a/amnsinternationalschool" + url;
            url_generated = url;
        }

        // For Live server
        else {
            url_generated = url;
        }
        return url_generated;
    }

    useEffect(() => {

        $("#btnsearch").on("click", function (e) {
            $("#searcherr").html("");
            if ($("#txtSearch").val() == "") {
                $("#searcherr").html("Please Enter Search String");
                return;
            }

            else {
                window.location.href = (global.variables.strDomainUrl + "search-results/" + $("#txtSearch").val());
            }
        });


        $('#txtSearch').keypress(function (e) {
            var key = e.which;
            if (key == 13)  // the enter key code
            {
                if ($("#txtSearch").val() == "") {
                    $("#searcherr").html("Please Enter Search String");
                    return;
                }
                else {
                    <Link to=""></Link>
                    window.location.href = url_generator(global.variables.strDomainUrl + "search-results/" + $("#txtSearch").val());
                }
                return false;
            }
        });

        $("#btnclickfocus").on("click", function () {

            setTimeout(() => {
                ref.current.focus();
            }, 300);
        })

    })




    const [academics, setAcademicsList] = useState([]);

    useEffect(() => {
        let _obj = new Object();
        _obj.acad_status = 1;
        setTimeout(() => {

            axios.post(global.variables.strApiUrl + "/acadApi/getacadList", _obj)
                .then((response_list) => {
                    // pass the result in the success function of the respective page
                    setAcademicsList(response_list.data[0]);
                });
        }, 300);

    }, [])

    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -134;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [fullscreen, setFullscreen] = useState(true);

    const location = useLocation();
    const { pathname } = location;

    //navbar active
    const splitLocation = pathname.split("/");


    useEffect(() => {
        // Go to Top OR Scroll to top
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation (comment behavior if u want to animate)
        });
    }, [pathname]);

    useEffect(() => {
        // initiate the wow effect
        new WOW.WOW({
            live: false
        }).init();

    }, []);


    //get reserach category list
    useEffect(() => {
        let _obj = new Object();
        _obj.rcategory_status = 1;
        //get research category list
        axios.post(global.variables.strApiUrl + '/rcategoryApi/getrcategoryList', _obj)
            .then((response_rcategory) => {
                setResearchCategoryList(response_rcategory.data[0]);
            })
    }, [qs_rcategory_id])

    const [socialMediaList, setSocialMediaList] = useState([])

    useEffect(() => {
        let _obj = new Object();
        _obj.social_status = 1;

        axios.post(global.variables.strApiUrl + "/socialApi/getSocialList", _obj)
            .then((response_list) => {
                if (response_list.data === "error") {
                    navigate("/error-page")
                }
                else {
                    // pass the result in the success function of the respective page
                    setSocialMediaList(response_list.data[0]);
                }
            });

    }, [])

    const handleclick = () => {
        var _class = document.getElementsByClassName("show");

        if (_class.length == 0) {

            $(document).ready(function () {
                window.location = window.location.href;

            });
        }
        else {
            $(document).ready(function () {
                window.location = window.location.href;

            });
        }


    }


    const [newstypeyear, setNewsTypeYearList] = useState(null);

    const [newsletteryear, setNewsLetterYearList] = useState(null);

    const [publicationyear, setPublicationYearList] = useState(null);

    const [reportyear, setReportYearList] = useState(null);
    
    // things to be done when the page is loaded
    useEffect(() => {

        //make the object for getting the news type year list
        let _objNewsTypeYear = new Object();
        _objNewsTypeYear._news_type_id = globalVariables.variables.strNewsTypeForNews_id;

        //Get news type yesr List
        axios.post(globalVariables.variables.strApiUrl + "/newsApi/getnewstypedistinctyear", _objNewsTypeYear)
            .then((response_list) => {

                if (response_list.data === "error") {
                    navigate("/error-page")
                }
                else {
                    // pass the result in the success function of the respective page
                    setNewsTypeYearList(response_list.data[0][0]?.news_year);

                }

            });


        //make the object for getting the report year list
        let _objNLetterYear = new Object();
        _objNLetterYear.nletter_status = 1;

        //Get news type yesr List
        axios.post(globalVariables.variables.strApiUrl + "/nletterApi/getnletterdistinctyear", _objNLetterYear)
            .then((response_list) => {

                if (response_list.data === "error") {
                    navigate("/error-page")
                }
                else {
                    // pass the result in the success function of the respective page
                    setNewsLetterYearList(response_list.data[0][0]?.nletter_year);

                }

            });

        //make the object for getting the news type year list
        let _objPublicationYear = new Object();
        _objPublicationYear.pub_status = 1;

        //Get pub type yesr List
        axios.post(globalVariables.variables.strApiUrl + "/publicationsApi/getpublicationsdistinctyearForWebadmin", _objPublicationYear)
            .then((response_list) => {

                if (response_list.data === "error") {
                    navigate("/error-page")
                }
                else {
                    // pass the result in the success function of the respective page
                    setPublicationYearList(response_list.data[0][0]?.pub_year);
                }

            });


        //make the object for getting the report year list
        let _objReportYear = new Object();
        _objReportYear.report_id = null;
        _objReportYear.report_status = 1;

        //Get report type yesr List
        axios.post(globalVariables.variables.strApiUrl + "/reportApi/getreportdistinctyearForWebadmin", _objReportYear)
            .then((response_list) => {

                if (response_list.data === "error") {
                    navigate("/error-page")
                }
                else {
                    // pass the result in the success function of the respective page
                    setReportYearList(response_list.data[0][0].report_year);

                }

            });


    }, []);


    return (
        <>
            <TrackingCode conversion_code={conversion_code} />
            <Navbar className={props.main_class + " fixed-top navbar py-0"} collapseonselect expand="xl">
                <div className="container-xxl d-flex flex-wrap justify-content-between">
                    <Link to="/" onClick={scrollToTop} className="d-flex align-items-center header-logo position-relative overflow-hidden">
                        <img src={global.variables.strDomainUrl + "images/logo.png"} className="img-fluid logo animated fadeInUp" alt="Indian Institute of Technology Bombay" title="Indian Institute of Technology Bombay" />
                        <img src={global.variables.strDomainUrl + "images/logo-caption.png"} className="img-fluid ms-md-3 ms-2 d-md-inline-block _d-none logo-caption animated fadeInUp" alt="Centre for Machine Intelligence and Data Science | C-MInDS" title="Centre for Machine Intelligence and Data Science | C-MInDS" />
                        {/* <div className="caption ms-md-3 ms-2 wow fadeInUp">
                            <h2>Centre for Machine Intelligence <br/>and Data Science</h2>
                            Indian Institute of Technology Bombay
                        </div> */}
                    </Link>

                    <div>
                        <div className="d-flex justify-content-end align-items-center">
                            <div className="animate fadeInUp me-xl-3">
                                <ul className="navbar-nav justify-content-end fw-normal top-nav">
                                    <li className="nav-item animated fadeInUp"><Link className="bg-transparent nav-link" onClick={handleShow} id='btnclickfocus'><BsSearch className="me-lg-2" /><span className="d-xl-inline-block d-none">Search</span></Link></li>

                                    <li className={`${splitLocation[1] === "recruitment" ? "active" : ""} nav-item d-xl-block d-none animated fadeInUp`} onClick={scrollToTop}><Link to="/recruitment" className="bg-transparent nav-link">Recruitment</Link></li>

                                    <li className={`${splitLocation[1] === "admissions" ? "active" : ""} nav-item d-xl-block d-none animated fadeInUp`} onClick={scrollToTop}><Link to="/admissions" className="bg-transparent nav-link">Admissions</Link></li>

                                    <li className={`${splitLocation[1] === "contact" ? "active" : ""} nav-item d-xl-block d-none animated fadeInUp`} onClick={scrollToTop}><Link to="/contact" className="bg-transparent nav-link">Contact Us</Link></li>
                                </ul>
                            </div>
                            <div className='d-none d-xl-block'>
                                <ul className="animate fadeInUp social_media social-media-top">

                                    {
                                        socialMediaList?.length > 0 ?

                                            socialMediaList?.map((val, index) => {
                                                return (

                                                    <li className="animated fadeInUp" key={index}>
                                                        {
                                                            val.social_img_name === 'FaFacebookF' ?
                                                                <a href={val.social_url} rel="noopener noreferrer" target="_blank" className="facebook"><FaFacebookF className="icon-24" /></a>
                                                                :
                                                                null

                                                        }
                                                        {
                                                            val.social_img_name === 'FaYoutube' ?
                                                                <a href={val.social_url} rel="noopener noreferrer" target="_blank" className="youtube"><FaYoutube className="icon-24" /></a>
                                                                :
                                                                null

                                                        }
                                                        {
                                                            val.social_img_name === 'FaXTwitter' ?
                                                                <a href={val.social_url} rel="noopener noreferrer" target="_blank" className="twitter"><FaXTwitter className="icon-24" /></a>
                                                                :
                                                                null

                                                        }
                                                        {
                                                            val.social_img_name === 'FaWhatsapp' ?
                                                                <a href={val.social_url} rel="noopener noreferrer" target="_blank" className="whatsapp"><FaWhatsapp className="icon-24" /></a>
                                                                :
                                                                null

                                                        }
                                                        {
                                                            val.social_img_name === 'FaInstagram' ?
                                                                <a href={val.social_url} rel="noopener noreferrer" target="_blank" className="instagram"><FaInstagram className="icon-24" /></a>
                                                                :
                                                                null

                                                        }
                                                        {
                                                            val.social_img_name === 'FaLinkedinIn' ?
                                                                <a href={val.social_url} rel="noopener noreferrer" target="_blank" className="linkedin"><FaLinkedinIn className="icon-24" /></a>
                                                                :
                                                                null

                                                        }
                                                        {
                                                            val.social_img_name === 'FaPinterest' ?
                                                                <a href={val.social_url} rel="noopener noreferrer" target="_blank" className="pinterest"><FaPinterest className="icon-24" /></a>
                                                                :
                                                                null

                                                        }
                                                        {
                                                            val.social_img_name === 'FaBlogger' ?
                                                                <a href={val.social_url} rel="noopener noreferrer" target="_blank" className="blogger"><FaBlogger className="icon-24" /></a>
                                                                :
                                                                null

                                                        }
                                                    </li>
                                                )
                                            })

                                            :
                                            null
                                    }
                                </ul>
                            </div>
                            <Navbar.Toggle className="offcanvas-toggle shadow-none" aria-controls={'offcanvas-menu'}>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </Navbar.Toggle>
                        </div>
                        <Navbar.Offcanvas id={'offcanvas-menu'} aria-labelledby={'offcanvas-menu'} placement="end" responsive="xl" className="offcanvas-end-nav">
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title>
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <ul className="navbar-nav mt-xl-auto justify-content-end  top-bt-nav position-relative ">
                                {/* <li className="nav-item dropdown">
                                    <Link to="/" className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">About Us</Link>
                                    <ul class="dropdown-menu">
                                        <li><a class="dropdown-item" href="#">Action</a></li>
                                        <li><a class="dropdown-item" href="#">Another action</a></li>
                                        <li><a class="dropdown-item" href="#">Something else here</a></li>
                                    </ul>
                                </li> */}



                                <li className={splitLocation[1] === "about" ? "active" : "" || splitLocation[1] === "reports" ? "active" : ""}>
                                    <NavDropdown title="About" id="basic-nav-dropdown" className="animated fadeInUp">
                                        <NavDropdown.Item as="li"><Link to={"/about"} onClick={scrollToTop}>Overview</Link></NavDropdown.Item>
                                        <NavDropdown.Item as="li"><Link to={"/about/" + global.variables.strPeopleCategoty_pagename} onClick={scrollToTop}>Founders and Donors</Link></NavDropdown.Item>
                                        <NavDropdown.Item as="li"><Link to={reportyear != null ? "/reports/" + reportyear : "/reports/" + new Date().getFullYear()} onClick={scrollToTop}>Reports</Link></NavDropdown.Item>
                                        <NavDropdown.Item as="li"><Link to="/recruitment" onClick={scrollToTop}>Recruitment</Link></NavDropdown.Item>
                                    </NavDropdown>
                                </li>

                                <li className={`${splitLocation[1] === "people" ? "active" : ""} nav-item animated fadeInUp`}><Link to="/people" className="nav-link" onClick={scrollToTop}>People</Link></li>

                                <li className={splitLocation[1] === "academics" ? "active" : "" || splitLocation[1] === "academic-details" ? "active" : ""}>
                                    <NavDropdown title="Academics" id="basic-nav-dropdown" className="animated fadeInUp">
                                        <NavDropdown.Item as="li"><Link to="/academics" onClick={scrollToTop}>Overview</Link></NavDropdown.Item>
                                        {
                                            academics.map((val) => {
                                                return (
                                                    <NavDropdown.Item as="li" key={val.acad_id}><Link to={global.variables.strDomainUrl + "academic-details/" + val.acad_pagename} onClick={scrollToTop}>{val.acad_course_name}</Link></NavDropdown.Item>
                                                )
                                            })
                                        }
                                    </NavDropdown>
                                </li>
                                <li className={splitLocation[1] === "research" ? "active" : "" || splitLocation[1] === "research-project" ? "active" : "" || splitLocation[1] === "publications" ? "active" : ""}>
                                    <NavDropdown title="Research" id="basic-nav-dropdown" className="animated fadeInUp">
                                        <NavDropdown.Item as="li"><Link to="/research" onClick={scrollToTop}>Overview</Link></NavDropdown.Item>

                                        {
                                            researchcat.length > 0 ?
                                                researchcat.map((val) => {
                                                    return (<NavDropdown.Item as="li" key={val.rcategory_id}><Link to={global.variables.strDomainUrl + "research-project/" + val.rcategory_id} onClick={scrollToTop}>{val.rcategory_name}</Link></NavDropdown.Item>)

                                                })
                                                : null
                                        }



                                        {/* <NavDropdown.Item as="li"><Link to="">Machine Learning</Link></NavDropdown.Item>
                                        <NavDropdown.Item as="li"><Link to="">Natural Language</Link></NavDropdown.Item>
                                        <NavDropdown.Item as="li"><Link to="">Computer Vision</Link></NavDropdown.Item>
                                        <NavDropdown.Item as="li"><Link to="">Deep Learning</Link></NavDropdown.Item> */}

                                        {/* <NavDropdown.Item as="li"><Link to={publicationyear != null ? "/publications/" + publicationyear : "/publications/" + new Date().getFullYear()} onClick={scrollToTop}>Publications</Link></NavDropdown.Item> */}
                                        <NavDropdown.Item as="li"><Link to={"/publications"} onClick={scrollToTop}>Publications</Link></NavDropdown.Item>
                                    </NavDropdown>
                                </li>
                                <li className={`${splitLocation[1] === "seminars" ? "active" : "" || splitLocation[1] === "seminars-details" ? "active" : ""} nav-item animated fadeInUp`}><Link to={"/seminars/recent"} className="nav-link" onClick={scrollToTop}>Seminars</Link></li>

                                <li className={splitLocation[1] === "news" ? "active" : "" || splitLocation[1] === "events" ? "active" : "" || splitLocation[1] === "newsletter" ? "active" : "" || splitLocation[1] === "news-details" ? "active" : "" || splitLocation[1] === "events-details" ? "active" : "" || splitLocation[1] === "thesis-defense" ? "active" : "" || splitLocation[1] === "thesis-defense-details" ? "active" : ""}>
                                    <NavDropdown title="News &amp; Events" id="basic-nav-dropdown" className="animated fadeInUp">
                                        <NavDropdown.Item as="li"><Link to={newstypeyear != null ? "/news/" + newstypeyear : "/news/" + new Date().getFullYear()} onClick={scrollToTop}>News Updates</Link></NavDropdown.Item>
                                        <NavDropdown.Item as="li"><Link to="/events/recent" onClick={scrollToTop}>Events</Link></NavDropdown.Item>
                                        <NavDropdown.Item as="li"><Link to="/thesis-defense/recent" onClick={scrollToTop}>Thesis Defense</Link></NavDropdown.Item>
                                        <NavDropdown.Item as="li"><Link to={newsletteryear != null ? "/newsletter/" + newsletteryear : "/newsletter/" + new Date().getFullYear()} onClick={scrollToTop}>Newsletter</Link></NavDropdown.Item>

                                    </NavDropdown>
                                </li>
                                {/* <li className={`${splitLocation[1] === "collaboration" ? "active" : "" } nav-item animated fadeInUp`}><Link to="/collaboration" className="nav-link" onClick={scrollToTop}>Collaboration</Link></li> */}
                                <li className={splitLocation[1] === "collaboration" ? "active" : "" || splitLocation[1] === "collaborationdetails" ? "active" : "" || splitLocation[1] === "honda-cars-india-limited" ? "active" : "" }>
                                    <NavDropdown title="Collaboration" id="basic-nav-dropdown" className="animated fadeInUp">
                                        <NavDropdown.Item as="li"><Link to="/collaboration">Overview</Link></NavDropdown.Item>
                                        <NavDropdown.Item as="li"><Link to="/collaborationdetails">SBI Technology Hub</Link></NavDropdown.Item>
                                        <NavDropdown.Item as="li"><Link to="/honda-cars-india-limited">Honda Cars India Limited (HCIL)</Link></NavDropdown.Item>
                                    </NavDropdown>
                                </li>
                                <li className="nav-item animated fadeInUp d-xl-none d-block"><Link to="/contact" className="nav-link" onClick={scrollToTop}>Contact Us</Link></li>
                            </ul>
                        </Navbar.Offcanvas>
                    </div>

                </div>

                <Modal show={show} fullscreen={fullscreen} onHide={handleClose} className="bg-search-panel">
                    <Modal.Header closeButton className="border-0 p-md-5 p-4 h5">
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="d-flex justify-content-center">
                        <Container>
                            <div className="col-md-8 mx-auto">
                                <InputGroup className="mb-3 border rounded bg-white align-items-center">
                                    <Form.Control
                                        className="border-0 form-control-lg shadow-none"
                                        placeholder="Search"
                                        aria-label="Search"
                                        aria-describedby=""
                                        required=""
                                        id='txtSearch'
                                        ref={ref}
                                    />
                                    <span id="searcherr" class="text-danger mx-2"></span>
                                    <Button variant="outline-dark border-0" id="btnsearch">
                                        <BsSearch />
                                    </Button>

                                </InputGroup>

                            </div>
                        </Container>
                    </Modal.Body>
                </Modal>
            </Navbar>
        </>
    )
} 