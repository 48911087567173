import { EventOnHomePageApiUrls , EventApiUrls } from "./api-urls";
import axios from "axios";
import globalVariables from "../global-variables";

export const GetEventOnHomePageList = (p_success_function,navigate) => {

    //Get visual panel List
	axios.get(globalVariables.variables.strApiUrl + EventOnHomePageApiUrls.get_event_homepage_api)
	.then((response_list) => {
        
		if(response_list.data === "error")
        {
             navigate("/error-page")
        }
        else
        {
			// pass the result in the success function of the respective page
			p_success_function(response_list.data[0]);
		}
        
	});

};


export const GetEventList = (p_objEvent , p_success_function, navigate) => {
    //Get visual panel List
	axios.post(globalVariables.variables.strApiUrl + EventApiUrls.get_event_api,p_objEvent)
	.then((response_list) => {
		if(response_list.data === "error")
        {
			navigate("/error-page")
        }
        else
        {
			// pass the result in the success function of the respective page
			p_success_function(response_list.data[0]);
		}
		
	});

};